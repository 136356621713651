@import '../components/responsive-utilities';


.text {
  @include breakpoint(md) {
    padding: $padding-large-vertical * 5 0;
    font-size: 19px;
  }

  margin: 0 auto;
  padding: $padding-large-vertical * 2 0;
  max-width: 640px;
  color: #2d2d2d;
  font-size: 17px;

  p {
    margin-bottom: $padding-large-vertical * 3;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

figure.video {
  position: relative;
  overflow: hidden;
  margin: $padding-large-horizontal 0;
  padding-top: 30px;
  padding-bottom: 56.25%;
  height: 0;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
figure.video.youtube-video {
  padding-bottom: 52.25%;
}

.text-intro {
  margin: 0 auto;
  padding: 50px 0;
  max-width: 780px;
  color: #2d2d2d;
  font-weight: 200;
  font-style: italic;
  font-size: 21px;
  p {
    margin-bottom: 0;
  }
}
