.home-page {
  p.info,
  p.editor,
  p.note {
    // float: left;
    /* display: block; */
    // margin: 10px 0 15px 0;
    a {
      border-bottom: 2px solid #fff;
      color: #fff;
      &:hover,
      &:visited,
      &[data-visited] {
        border-bottom: 2px solid #fff;
        color: #fff;
      }
    }
  }
}
p.info,
p.editor,
p.note {
  // float: left;
  // /* display: block; */
  // margin: 10px 0 15px 0;
  a {
    border-bottom: 2px solid #fff;
    color: #fff;
    &:hover,
    &:visited,
    &[data-visited] {
      border-bottom: 2px solid #fff;
      color: #fff;
    }
  }
}

// .content {
p.note {
  // float: left;
  // /* display: block; */
  // margin: 10px 0 15px 0;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  background-color: #238cfa;
  // background-color: #c40d11;
  color: #fff;
  // color: #111;
  // color: rgba(255,255,255,1);
  // color: #111;
  font-weight: 600;
  // background-color: rgba(35,140,250,1);
  a {
    // border-color: #111;
    border-color: rgba(255,255,255,1);
    // color: #fff;
    // color: #111;
    color: rgba(255,255,255,1);
    color: #111;
    text-decoration: none;
    &:hover,
    &:visited,
    &[data-visited] {
      // border-color: #111;
      border-color: rgba(255,255,255,1);
      color: #111;
      text-decoration: none;
    }
  }
}
// }
// .dark-mode .content {
.dark-mode {
  p.note {
    // float: left;
    // margin: 10px 0 15px 0;
    padding: 16px;
    width: 100%;
    border-radius: 5px;
    background-color: #238cfa;
    // background-color: #c40d11;
    // color: #fff;
    color: #111;
    font-weight: 600;
    // background-color: rgba(35,140,250,1);
    a {
      border-color: #111;
      // color: #fff;
      color: #111;
      text-decoration: none;
      &:hover,
      &:visited,
      &[data-visited] {
        border-color: #111;
        color: #111;
        text-decoration: none;
      }
    }
  }
}





// .content {
p.info {
  // float: left;
  // margin: 10px 0 15px 0;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(12,242,143,.2);
  color: #111;
  // background-color: #c40d11;
  // color: #fff;
  // color: #111;
  // color: rgba(255,255,255,1);
  font-weight: 600;
  // background-color: rgba(35,140,250,1);
  a {
    border-color: #111;
    // border-color: rgba(255,255,255,1);
    // color: #fff;
    color: #111;
    // color: rgba(255,255,255,1);
    text-decoration: none;
    &:hover,
    &:visited,
    &[data-visited] {
      border-color: #111;
      color: #111;
      // border-color: rgba(255,255,255,1);
      text-decoration: none;
    }
  }
}
// }
// .dark-mode .content {
.dark-mode {
  p.info {
    // float: left;
    // margin: 10px 0 15px 0;
    padding: 16px;
    width: 100%;
    border-radius: 5px;
    background-color: #23fa92;
    // background-color: #c40d11;
    // color: #fff;
    color: #111;
    font-weight: 600;
    // background-color: rgba(35,140,250,1);
    a {
      border-color: #111;
      // color: #fff;
      color: #111;
      text-decoration: none;
      &:hover,
      &:visited,
      &[data-visited] {
        border-color: #111;
        color: #111;
        text-decoration: none;
      }
    }
  }
}


// .content {
p.editor {
  // float: left;
  // margin: 10px 0 15px 0;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  background-color: #fefcb4;
  color: #111;
  font-weight: 600;
  a {
    border-color: #111;
    color: #111;
    text-decoration: none;
    &:hover,
    &:visited,
    &[data-visited] {
      border-color: #111;
      color: #111;
      text-decoration: none;
    }
  }
}
// }
// .dark-mode .content {
.dark-mode {
  p.editor {
    // float: left;
    // margin: 10px 0 15px 0;
    padding: 16px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(254,252,180,.9);
    // background-color: #c40d11;
    // color: #fff;
    color: #111;
    font-weight: 600;
    // background-color: rgba(35,140,250,1);
    a {
      border-color: #111;
      // color: #fff;
      color: #111;
      text-decoration: none;
      &:hover,
      &:visited,
      &[data-visited] {
        border-color: #111;
        color: #111;
        text-decoration: none;
      }
    }
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


body.pdf-cert {
  // background-color: #eee;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

p.info > i {
  font-size: 22px;
}
p.editor > i {
  font-size: 22px;
}
.content p.note > i {
  fontze: 22px;
}
p.info.pink {
  background-color: #ff42a1;
  color: #fff;
  i,
  a {
    border-bottom-color: #fff;
    color: #fff;
    &:hover {
      border-bottom-color: #fff;
      color: #fff;
    }
  }
}
.dark-mode {
  p.info.pink {
    a {
      border-color: #fff;
      color: #fff;
      &:hover {
        border-color: #fff;
        color: #fff;
      }
    }
  }
}
p.info.yellow {
  background-color: #fefcb4;
}
p.info.green {
  background-color: #23fa92;
}
p.info.darkblue {
  background-color: #00226d;
  color: #fff;
  i,
  a {
    border-bottom-color: #fff;
    color: #fff;
    &:hover,
    &:visited,
    &[data-visited] {
      border-bottom-color: #fff;
      color: #fff;
    }
  }
}
p.info.blue {
  background-color: #238cfa;
  color: #fff;
  i,
  a {
    border-bottom-color: #fff;
    color: #fff;
    &:hover {
      border-bottom-color: #fff;
      color: #fff;
    }
  }
}
p.info.red {
  // border-bottom-color: #fff;
  background-color: rgb(236, 29, 29);
  color: #fff;
  i,
  a {
    border-bottom-color: #fff;
    color: #fff;
    &:hover,
    &:visited,
    &[data-visited] {
      border-bottom-color: #fff;
      color: #fff;
    }
  }
}

.light-text {
  opacity: .6;
}


// end of file
