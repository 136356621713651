.event-content {
  padding-bottom: 70px;
}
.content figure {
  padding-bottom: 20px;
}
.half {
  float: left;
  width: 50%;
}
.space-left {
  padding-left: 15px;
}
.space-right {
  padding-right: 15px;
}


.dot {
  position: absolute;
  z-index: 5;
  display: inline-block;
  margin-top: -13px;
  margin-left: 28px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #c40d11;
}
.tag {
  display: inline-block;
  // margin-top: -5px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 4px 6px 4px 6px;
  height: 27px;
  border-radius: 5px;
  background-color: #c40d11;
  color: #fff;
  color: rgba(255,255,255,.9);
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}
li:hover.tag {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .tag {
    display: inline-block;
    margin-left: 0;
  }
}

.content figure.video {
  padding-bottom: 56.25%;
}


.alert {
  // margin-bottom: 19px;
  margin-bottom: 10px;
}
.alert-warning {
  border-color: #ffd454;
  background-color: #ffd454;
  // color: #fff;
  color: #111;
  font-weight: 500;
}
.alert-neutral {
  border-color: #238cfa;
  background-color: #238cfa;
  color: #fff;
  font-weight: 600;
}
.dark-mode {
  .alert-neutral {
    color: #111;
  }
}


#checkout-errors.red {
  margin-top: 10px;
  margin-bottom: 19px;
  padding: 10px 15px;
  border: 1px solid transparent;
  // border-color: rgb(236, 29, 29);
  border-color: #ffd454;
  border-radius: 3px;
  // background-color: rgb(236, 29, 29);
  background-color: #ffd454;
  color: #111 !important;
  font-weight: 500;
  /* font-weight: 600; */
}
