.block {
  @include breakpoint(md) {
    margin: $padding-base-vertical * 8 0;
  }

  margin: $padding-base-vertical * 3 0;
  padding: $padding-large-horizontal * 2;
  max-width: 512px;
  border: 1px solid darken($gray-lighter, 5%);
  border-bottom: 2px solid darken($gray-lighter, 5%);
  border-radius: $border-radius-base;
  background-color: $light;

  &.block-center {
    margin-right: auto;
    margin-left: auto;
  }
}
