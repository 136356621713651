.language-box {
  z-index: 2;
}

.languages-menu {
  z-index: 2;
  float: right;
}
ul.languages {
  // right: 30px;
  margin-top: 8px;
  margin-bottom: 0;
}

ul.languages li {
  display: inline-block;
  // margin-left: 5px;
  margin-left: 10px;
}

ul.languages li a {
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: none;
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
  color: #272727;
  text-decoration: none;
  font-size: 15px;
}

ul.languages li a i {
  display: inline-block;
  width: 19px;
  height: 15px;
}

ul.languages li a i.icon-en {
  // background-image: url('/assets/flags/en.png');
  background-image: url('/assets/flags/en.svg');
  background-size: cover;
}
ul.languages li a i.icon-br {
  // background-image: url('/assets/flags/br.png');
  background-image: url('/assets/flags/br.svg');
  background-size: cover;
}
ul.languages li a i.icon-it {
  // background-image: url('/assets/flags/br.png');
  background-image: url('/assets/flags/it.svg');
  background-size: cover;
}
ul.languages li a i.icon-es {
  // background-image: url('/assets/flags/br.png');
  background-image: url('/assets/flags/es.svg');
  background-size: cover;
}
ul.languages li a i.icon-no {
  // background-image: url('/assets/flags/br.png');
  background-image: url('/assets/flags/no.svg');
  background-size: cover;
}
ul.languages li a i.icon-dk {
  // background-image: url('/assets/flags/br.png');
  background-image: url('/assets/flags/dk.svg');
  background-size: cover;
}

// ul.languages li a i.icon-dk {
//   background-image: url('/assets/flags/dk.png');
//   background-size: cover;
// }
//
// ul.languages li a i.icon-no {
//   background-image: url('/assets/flags/no.png');
//   background-size: cover;
// }
// ul.languages li a i.icon-it {
//   background-image: url('/assets/flags/it.png');
//   background-size: cover;
// }
//
// ul.languages li a i.icon-us {
//   background-image: url('/assets/flags/us.png');
//   background-size: cover;
// }

ul.languages li.active a,
ul.languages li.active a i {
  // color: #171717!important;
  opacity: .3;
}

// .languages-menu {
//   position: absolute;
//   top: 10px;
//   right: 30px;
// }

@media screen and (max-width: 520px) and (min-width: 310px) {
  .languages-menu {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }


  ul.languages {
    padding-inline-start: 0;
  }

  ul.languages li {
    display: inline-block;
    overflow: hidden;
    // margin-left: 5px;
    margin-left: 7px;
    // width: 22%;
    // color: rgba(39,39,39,.3);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ul.languages li.active {
    color: rgba(39,39,39,.2);
  }
  // ul.languages li a {
  //   color: rgba(39,39,39,.3);
  // }
  dark-mode {
    ul.languages li.active {
      color: rgba(170,170,170,1);
    }
    ul.languages li.active a:visited {
      color: rgba(170,170,170,1);
    }
  }
}
