.checkout-page {
  padding: 50px 0;

  form {
    label {
      padding-left: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      opacity: .7;
    }

    #checkout-errors {
      color: $brand-danger;
    }

    .StripeElement--focus {
      border-color: $brand-primary;
    }
  }

  .product {
    margin: 28px auto;
    max-width: 320px;
    background-color: #f9f9f9;

    > .content {
      padding: 20px;

      > .title {
        margin-top: 0;
        margin-bottom: 7px;
        font-size: 22px;
        line-height: 1.2;
      }
      > .proddisc {
        padding-top: 7px;
      }
    }
  }
}
