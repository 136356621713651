/* line 4, ../sass/_offline-theme-base.sass */
.offline-ui,
.offline-ui *,
.offline-ui:before,
.offline-ui:after,
.offline-ui *:before,
.offline-ui *:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/* line 7, ../sass/_offline-theme-base.sass */
.offline-ui {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  display: none;
  margin: auto;
  background: white;
}
/* line 17, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-content:before {
  display: inline;
}
/* line 20, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry {
  display: none;

  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
/* line 24, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry:before {
  display: inline;
}
/* line 29, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-up.offline-ui-up-5s {
  display: block;
}
/* line 32, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down {
  display: block;
}
/* line 37, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-retry {
  display: block;
}
/* line 42, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting .offline-ui-retry {
  display: none;
}

@-webkit-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-moz-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-ms-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-o-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    display: block;
    opacity: 1;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    display: block;
    opacity: 0;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    display: none;
    opacity: 0;
  }
}

@-moz-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    display: block;
    opacity: 1;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    display: block;
    opacity: 0;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    display: none;
    opacity: 0;
  }
}

@-ms-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    display: block;
    opacity: 1;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    display: block;
    opacity: 0;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    display: none;
    opacity: 0;
  }
}

@-o-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    display: block;
    opacity: 1;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    display: block;
    opacity: 0;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    display: block;
    opacity: 1;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    display: block;
    opacity: 0;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    display: none;
    opacity: 0;
  }
}

@-webkit-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    -webkit-transform: rotate(359deg);
       -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
         -o-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-moz-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    -webkit-transform: rotate(359deg);
       -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
         -o-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-ms-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    -webkit-transform: rotate(359deg);
       -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
         -o-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-o-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    -webkit-transform: rotate(359deg);
       -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
         -o-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    -webkit-transform: rotate(359deg);
       -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
         -o-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

/* line 21, ../sass/offline-theme-default.sass */
.offline-ui {
  top: 1em;
  overflow: hidden;
  padding: 1em;
  max-width: 100%;
  width: 38em;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  font-family: 'Helvetica Neue', sans-serif;

      -ms-border-radius: 4px;
       -o-border-radius: 4px;
}
@media (max-width: 38em) {
  /* line 21, ../sass/offline-theme-default.sass */
  .offline-ui {
    top: 0;
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;

        -ms-border-radius: 0;
         -o-border-radius: 0;
  }
}
/* line 34, ../sass/offline-theme-default.sass */
.offline-ui .offline-ui-content:before {
  line-height: 1.25em;
}
/* line 37, ../sass/offline-theme-default.sass */
.offline-ui .offline-ui-retry {
  position: absolute;
  top: 0;
  right: 3em;
  bottom: 0;
  margin: auto;
  padding: 0 1em;
  height: 3.5em;
  background: rgba(0, 0, 0, .1);
  color: inherit;
  text-decoration: none;
  line-height: 3.5em;
}
/* line 50, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-up {
  background: #d6e9c6;
  color: #468847;
  -webkit-animation: offline-fadeout-and-hide forwards .5s 2s;
     -moz-animation: offline-fadeout-and-hide forwards .5s 2s;
      -ms-animation: offline-fadeout-and-hide forwards .5s 2s;
       -o-animation: offline-fadeout-and-hide forwards .5s 2s;
          animation: offline-fadeout-and-hide forwards .5s 2s;

  -webkit-backface-visibility: hidden;
}
/* line 55, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down {
  background: #ec8787;
  color: #551313;
  -webkit-animation: offline-fadein .5s;
     -moz-animation: offline-fadein .5s;
      -ms-animation: offline-fadein .5s;
       -o-animation: offline-fadein .5s;
          animation: offline-fadein .5s;

  -webkit-backface-visibility: hidden;
}
/* line 60, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-connecting,
.offline-ui.offline-ui-down.offline-ui-waiting {
  padding-right: 3em;
  background: #f8ecad;
  color: #7c6d1f;
}
/* line 65, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-connecting:after,
.offline-ui.offline-ui-down.offline-ui-waiting:after {
  position: absolute;
  top: 0;
  right: 1em;
  bottom: 0;
  display: block;
  margin: auto;
  width: 1em;
  height: 1em;
  border: 2px solid rgba(0, 0, 0, 0);
  border-top-color: #7c6d1f;
  border-left-color: #7c6d1f;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
          border-radius: 50%;
  content: ' ';
  opacity: .7;
  -webkit-animation: offline-rotation .7s linear infinite;
     -moz-animation: offline-rotation .7s linear infinite;
      -ms-animation: offline-rotation .7s linear infinite;
       -o-animation: offline-rotation .7s linear infinite;
          animation: offline-rotation .7s linear infinite;

  -webkit-backface-visibility: hidden;
      -ms-border-radius: 50%;
       -o-border-radius: 50%;
}
/* line 82, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-waiting {
  padding-right: 11em;
}
/* line 85, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-waiting.offline-ui-reconnect-failed-2s {
  padding-right: 0;
}
