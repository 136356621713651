.alert-success {
  border-color: #23fa92;
  background-color: #23fa92;
  color: #111;
  font-weight: 600;
  button span,
  .close {
    visibility: hidden;
  }
}

.alert-danger {
  border-color: #ffd454;
  background-color: #ffd454;
  // border-color: rgb(236, 29, 29);
  // background-color: rgb(236, 29, 29);
  color: #111;
  // font-weight: 400;
  font-weight: 600;
  button span,
  .close {
    visibility: hidden;
  }
}
.alert-success .close,
.alert-danger .close {
  margin-top: -5px;
  margin-right: -38px;
  color: #fff;
}
.alert-success .close,
.alert-danger .close {
  &::before {
    background-color: #fff;
  }
  &::after {
    background-color: #fff;
  }
}
