

figure {
  padding: 10px 0;
}

span.mark {
  // padding: .2em;
  padding: 3px 6px;
  border-radius: 3px;
  color: #111;
  // line-height: 1.1;
  // background-color: $state-warning-bg;
  &.yellow {
    background-color: rgb(254,252,180);
    color: #111;
  }
  &.green {
    // background-color: rgb(214,252,233);
    background-color: rgba(12,242,143,.2);
    color: #111;
  }
  &.blue {
    // background-color: rgb(184,231,246);
    // background-color: rgb(180,216,253);
    background-color: rgba(182, 217, 252, .57);
    color: #111;
  }
  &.pink {
    background-color: rgba(255,66,161,.25);
    color: #111;
  }
  &.grey {
    background-color: rgb(239,239,239);
    color: #111;
  }
  &.red {
    background-color: #c40d11;
    color: #fff;
    // font-weight: 700;
  }
}

mark.mark {
  // padding: .2em;
  padding: 3px 6px;
  border-radius: 3px;
  color: #111;
  // line-height: 1.1;
  // background-color: $state-warning-bg;
  &.yellow {
    background: rgb(254,252,180);
    background-color: rgb(254,252,180);
    color: #111;
  }
  &.green {
    // background-color: rgb(214,252,233);
    background: rgba(12,242,143,.2);
    background-color: rgba(12,242,143,.2);
    color: #111;
  }
  &.blue {
    // background: rgb(184,231,246);
    // background-color: rgb(184,231,246);
    background: rgba(182, 217, 252, .57);
    background-color: rgba(182, 217, 252, .57);
    color: #111;
  }
  &.pink {
    background: rgba(255,66,161,.25);
    background-color: rgba(255,66,161,.25);
    color: #111;
  }
  &.grey {
    background: rgb(239,239,239);
    background-color: rgb(239,239,239);
    color: #111;
  }
  &.red {
    background-color: #c40d11;
    color: #fff;
    // font-weight: 700;
  }
}

a {
  overflow: hidden;
  // border-bottom: 2px solid #077fb6;
  border-bottom: 2px solid #5f4b8b;
  // TESTING blue
  border-bottom: 2px solid #238cfa;
  // color: #5f4b8b;
  color: #238cfa;
  text-overflow: ellipsis;
  white-space: wrap;
}

header a,
.social-foo a,
header a:hover,
.social-foo a:hover {
  border-bottom: none;
}

.btn-outline-primary {
  border-color: #238cfa;
  background-color: transparent;
  color: #238cfa;
}
.btn-outline-primary:hover {
  border-color: #238cfa;
  background-color: #238cfa;
  color: #fff;
}
.btn-primary {
  border-color: #238cfa;
  background-color: #238cfa;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:target,
.btn-primary:link {
  border-color: #238cfa;
  background-color: #238cfa;
  color: #fff;
}

.content a:visited,
.content a[data-visited] {
  border-bottom: 2px solid  #111;
  color: #111;
}
.content a:visited:hover,
.content a[data-visited]:hover {
  border-bottom: 2px solid  #111;
  color: #111;
}
a.button-full:visited,
a.button-full[data-visited],
a.button-full:visited:hover,
a.button-full[data-visited]:hover {
  border-bottom: none;
  color: #fff;
}
.button-full {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.button:visited,
a.button[data-visited],
a.button:visited:hover,
a.button[data-visited]:hover {
  border-bottom: none;
  color: #fff;
}
.button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}




.content {
  font-size: 18px;
  line-height: 1.5;
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  p {
    margin-bottom: $padding-large-vertical * 2; // was 3
  }
}

.side-letters {
  overflow: hidden;
  margin: 0;
  padding: 0;
  h1 {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    margin: 0;
    margin-top: 550px;
    margin-right: -610px;
    padding: 0;
    color: rgba(170,170,170,.05);
    // rgb(39,39,39) #272727
    // rgb(23,23,23) #171717
    // rgb(170,170,170) #aaaaaa
    text-align: right;
    font-size: 200px;
    line-height: 1;
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}


// .home-page
.home-page .hero h1 {
  font-size: 56px;
  line-height: 1;
}

.blue-icon {
  color: #238cfa;
}

.menu-night-icon {
  margin-top: 4px;
  margin-right: 4px;
}

.mini-text {
  // padding-top: 11px;
  padding-top: 20px;
  padding-bottom: 6px;
  font-weight: 600;
  font-size: 11px;
}

.button,
.btn {
  // padding: 12px 12px;
  padding: 12px 12px 10px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 800;
}

.infobar-full .close-button {
  margin-top: -50px;
  font-size: 22px;
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
}
h2 {
  text-transform: uppercase;
  font-weight: 900;
}
h3 {
  text-transform: uppercase;
  font-weight: 900;
}
h4 {
  // text-transform: uppercase;
  font-weight: 900;
}


.foomenu {
  text-align: left;
  font-size: 18px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline;
      list-style: none;

      a {
        display: inline-block;
        background: none;
        &:hover {
          background: none;
        }
        &:visited:hover {
          background: none;
        }
        &[data-visited]:hover {
          background: none;
        }
        &:visited {
          background: none;
        }
        &[data-visited] {
          background: none;
        }
      }
    }
  }
}

.social-foo {
  padding: 30px 0 10px;
  .social-foo-item {
    display: inline;
    a {
      padding: 8px;
      border-bottom: none;
      text-decoration: none;
      &:hover {
        border-bottom: none;
        text-decoration: none;
      }
      i {
        font-size: 22px;
      }
    }
  }
}

.copyright {
  font-size: 12px;
}

div.menu-toggle {
  top: 40px;
  margin-top: 0;
  padding: 2px;
}

a.logo .image {
  margin-top: -10px;
}

.header-center-text {
  display: block;
  // padding-top: 10px;
  // padding-top: 35px;
  // padding-bottom: 35px;
  // margin-top: -110px; // Top edge
  // margin-top: -110px;
  // margin-top: -4%;
  margin-top: -75px;
  margin-bottom: 35px;
  // margin-left: 31.6%;
  text-align: center;
  a {
    // display: inline-block;
  }
}



.page-header-title {
  display: inline-block;
  padding-left: 20px;
  // margin-top: -80px;
  // padding-bottom: 35px;
  text-align: center;
  h1.logo-text {
    text-transform: uppercase;
    font-weight: 700;
  }
  h1.logo-text span.logo-light {
    font-weight: 300;
  }
  h1 {
    margin: 0 auto;
    margin-top: -12px;
    color: #272727;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.618;
  }
}



@media screen and (max-width: 768px) {
  h1.logo-text {
    margin-top: -5px;
    // padding-left: 15px;
  }
  // div.menu-toggle {
  //   margin-top: -8px;
  // }
  .header-center-text {
    margin-bottom: 20px;
    // padding-left: 3%;
  }
  a.logo svg#logo-svg.logo-svg {
    width: 90px;
    height: 90px;
  }
}
@media screen and (max-width: 488px) {
  span.logo-light {
    display: block;
    margin-bottom: -10px;
    text-align: left;
  }
  div.menu-toggle {
    // margin-top: -10px;
  }
}

a.logo svg path {
  fill: #272727 !important;
}
a.logo svg {
  fill: #272727 !important;
}

.pre-loader {
  display: inline;
}
.pre-loader .color-loader {
  fill: #272727;
  stop-color: #272727;
}
.pre-loader .image svg {
  fill: #272727;
}

.hero-space {
  margin: 70px 0;
}

@media screen and (max-height: 600px) {
  .hero-space {
    margin: 10px 0;
  }
}

// @media screen and (max-width: 380px) {
//   .page-header-title {
//     display: none;
//   }
//   .menu-toggle {
//     margin-top: 4px !important;
//   }
// }

@media screen and (max-width: 420px) {
  .home-page .hero h1 {
    font-size: 32px;
  }
  .menu-toggle {
    // margin-top: -12px !important;
    margin-top: -18px;
  }
}

@media screen and (max-width: 380px) {
  .page-header-title {
    display: none;
  }
  .menu-toggle {
    // margin-top: 4px !important;
  }
}

@media screen and (min-width: 421px) and (max-width: 660px) {
  .home-page .hero h1 {
    font-size: 42px;
  }
  .menu-toggle {
    margin-top: -18px;
  }
}

// header {
//   background-color: #252525;
// }

// .foo-section {
//   background-color: #252525;
// }




// @media not all and (min-resolution:.001dpcm) {
//   @supports (-webkit-appearance:none) {
//     .safari_only {
//       background-color: #ccc;
//       color: #00f;
//     }
//     .header-center-text {
//       margin-top: -85px;
//       padding-top: 18px;
//     }
//   }}



.section-breadcrumb {
  margin: 0;
  margin-left: -15px;
  padding: 0;
  padding: 10px;
  padding-left: 15px;
  // background-color: rgba(194, 178, 128, .3);
  // background-color: #eee;
  background-color: rgba(238,238,238,.4);
}
.breadcrumb {
  display: inline;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: none;
  list-style: none;
  i {
    font-size: 12px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline;
      padding: 4px 8px;
      list-style: none;
      a {
        border-bottom: none;
        &:hover {
          border-bottom: 2px solid #077fb6;
        }
      }
    }
  }
}

.top-movie-wrap {
  padding-top: 35px;
}
.space-top-fix {
  padding-top: 35px;
}


.article-item {
  display: block;
  padding-bottom: 60px;
  &:first-of-type {
    // padding-top: 40px;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
}
.tagandtime {
  padding-top: 10px;
}
.article-text {
  // margin-top: 10px;
  padding-top: 10px;
  // border-top: 1px solid #f1f1f1;
}
.timeago-text {
  font-style: italic;
  opacity: .7;
}
.article-tags {
  font-style: italic;
}

.article-intro-title {
  margin-top: -6px;
  // padding-bottom: 10px;
  margin-bottom: -6px;
  // border-bottom: 2px solid rgba(37,37,37,.1);
  h2 {
    margin-top: 9px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
}
.articles.index h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
}
.article.single h2 {
  margin-top: 16px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.article-tags {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    list-style: none;
    font-weight: 400;
    // span span {
    //   font-weight: 700;
    // }
  }
}


.menu li a {
  color: rgba(255,255,255,.7);
}



.extra-padding-top {
  padding: 10px;
}
.extra-padding-foo {
  padding: 60px;
}
.lander-correction-topvideo {
  margin-top: -60px;
}



// Mobile and resonsive
@media screen and (max-width: 420px) {
  body {
    font-size: 16px;
  }
  .mini-text {
    font-size: 11px;
  }
  .articles.index h2,
  .article.single h2 {
    font-size: 26px;
  }
  .tagandtime {
    .timeago-text,
    .article-tags {
      font-size: 12px;
    }
  }
  .article-item {
    padding-bottom: 15px;
  }
  .blog-list {
    padding-top: 15px;
  }
  .pagination {
    margin: 5px 0;
  }
  .content {
    font-size: 16px;
    p {
      margin-bottom: 10px;
    }
  }
  .top-movie-wrap {
    padding-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  pre {
    padding: 10px;
    font-size: 14px;
  }
  .space-top-fix {
    padding-top: 10px;
  }
  .form-control {
    font-size: 16px;
  }
  .extra-padding-top {
    padding: 20px;
  }
  .extra-padding-foo {
    padding: 40px;
  }
  .lander-correction-topvideo {
    margin-top: 0;
  }
}

.onelinetext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.less-side {
  padding-right: 0;
  padding-left: 0;
}

.more-stories {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.more-title {
  padding-bottom: 10px;
}
