// TWITTER HEART
.heart {
  width: 100px;
  height: 100px;
  // background: url('//127.0.0.1:9000/assets/like/heart.png') no-repeat;
  // background: url('https://www.baptistella.xyz/assets/like/heart.png') no-repeat;
  background: url('//www.baptistella.xyz/assets/like/heart.png') no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;

  &.is-active {
    background-position: -2800px 0;
    transition-duration: 1s;
  }
}

.likethis {
  margin-top: -40px;
  margin-right: -38px;
  margin-bottom: -30px;
}
@media screen and (min-width: 781px) and (max-width: 980px) {
  .likethis {
    margin-top: -60px;
  }
}
@media screen and (min-width: 481px) and (max-width: 780px) {
  .likethis {
    margin-top: -30px;
    margin-right: -28px;
  }
}
@media screen and (max-width: 480px) {
  .likethis {
    position: absolute;
    top: 0;
    right: 10px;
    margin-top: -20px;
    margin-right: -24px;
  }
}
.like-feedback-message {
  .alert {
    margin-top: 10px;
    // margin-bottom: -10px;
  }
}
