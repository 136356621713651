// @font-face {
//     font-family: 'Verb Ultra';
//     src: url('VerbUltra-Italic.woff2') format('woff2'),
//         url('VerbUltra-Italic.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;
// }
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

span.wabisabi.light {
  font-weight: 300;
  font-family: 'Kalam', cursive;
}
span.wabisabi.normal {
  font-weight: 400;
  font-family: 'Kalam', cursive;
}
span.wabisabi.bold {
  font-weight: 700;
  font-family: 'Kalam', cursive;
}
span.wabisabi {
  color: #111;
}
span.wabisabi.blue {
  color: #238cfa;
}
span.wabisabi.yellow {
  // color: #ffe200;
  color: #ffd600;
}
span.wabisabi.green {
  color: #23fa92;
}
span.wabisabi.pink {
  color: rgba(255,66,161,1);
}
span.wabisabi.red {
  color: #c40d11;
}
span.wabisabi.grey {
  color: rgb(135,135,135);
}
span.wabisabi.lower {
  text-transform: lowercase;
}
span.wabisabi.upper {
  text-transform: uppercase;
}

// span.wabisabi.white {
//   color: #fff;
// }
