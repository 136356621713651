body {
  margin: 0;
  padding: 0;
  background-color: #f5f4f1;
  color: #454545;
  letter-spacing: -.4px;
  font-family: 'Verb';
  line-height: 1.618;

  -webkit-font-smoothing: antialiased;
}

// img {
// width: 100%;
// height: auto;
// }

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

figcaption,
span.caption {
  opacity: .8;
}

/* Infobar in the top with background colors */

.close {
  position: absolute;
  // top: 32px;
  right: 32px;
  // margin-top: -22px;
  width: 32px;
  height: 32px;
  opacity: .3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  width: 2px;
  height: 33px;
  background-color: #333;
  content: ' ';
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}


.close-button a {
  // padding: 10px;
  border-bottom: none;
  color: #171717;
}
.close-button a:hover {
  border-bottom: none;
  color: #171717;
}

.infobar {
  // padding-left: 52px;
  margin: 0 60px;
  text-align: center;
}

@media screen and (max-width: 488px) {
  .infobar {
    margin: 10px 10px 50px 10px;
    text-align: center;
  }
}


.infobar-full {
  margin-top: -5px;
  -webkit-box-shadow: 0 1px 8px 1px rgba(23,23,23,.4);
     -moz-box-shadow: 0 1px 8px 1px rgba(23,23,23,.4);
          box-shadow: 0 1px 8px 1px rgba(23,23,23,.4);
}
.infobar-full .infobar {
  padding-top: 1.4em;
  padding-bottom: 1em;
}
.infobar-full .infobar p {
  margin-bottom: 0;
  padding-bottom: 0;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.infobar-full.yellow {
  background: #fcf403;
  /* 8C1619 */
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
  .close-button a {
    border-bottom: none;
  }
}
.infobar-full.yellow .infobar p {
  color: #272727;
}
.infobar-full.red {
  background: rgb(236, 29, 29);
  color: #fff;
  // text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
  font-weight: 700;
  opacity: 1;
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
  .close-button a {
    border-bottom: none;
  }
}
.infobar-full.yellow .infobar p {
  color: #272727;
}
.infobar-full.red a {
  border-color: #fff;
  color: #fff;
  opacity: 1;
}




.infobar-full.red .infobar p {
  color: #fff;
}
.infobar-full.red a:hover {
  border-color: #fff;
  color: #fff;
  opacity: .6;
}
.infobar-full.green {
  background: #42d00b;
  color: #000;
  font-weight: 700;
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
}
.infobar-full.white {
  background: #fff;
}
.infobar-full.white .infobar p {
  color: #272727;
}
.infobar-full.blue {
  /* background: #272C3E; */
  /* background: #31374e; */
  background: #238cfa;
  /* background: #363d56; */
  color: #fff;
  font-weight: 700;
  .close-button a {
    border-bottom: none;
  }
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
}
.infobar-full.blue .infobar p {
  color: #fff;
  a {
    border-bottom: solid 2px #fff;
    color: #fff;
  }
}


.infobar-full.plastic {
  background: #23fa92;
  font-weight: 700;
}
.infobar-full.plastic .infobar p {
  color: #272727;
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
}


.infobar-full.gray {
  background: #ebebeb;
  font-weight: 700;
}
.infobar-full.gray .infobar p {
  color: #272727;
  a {
    border-bottom: solid 2px #272727;
    color: #272727;
    &:hover {
      opacity: .8;
    }
  }
}

.infobar-full.dark {
  background: #252525;
  font-weight: 700;
}
.infobar-full.dark .infobar p {
  color: #aaa;
  a {
    border-bottom: solid 2px #aaa;
    color: #aaa;
    &:hover {
      opacity: .8;
    }
  }
}
.infobar-full.dark .close-button a {
  border-color: #aaa;
  border-bottom: none;
  color: #aaa;
  &:before {
    background-color: #aaa;
  }
  &:after {
    background-color: #aaa;
  }
}
