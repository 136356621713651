
.lander-list-space li
  padding-bottom: 7px

.form-control
  color: #111

.form-group .form-control
  color: #111

.dark-mode .form-control
  color: #fff

.dark-mode .form-group .form-control
  color: #fff

.ElementsApp,
.ElementsApp .InputElement
  color: #111

#card-element input,
#card-expiry-element input,
#card-cvc-element input
  color: #111

header.light
  padding-top: 37px
.end-spacer
  margin-bottom: 230px

// Removing some vertical space for mobile screens.
@media (max-width: 480px)
  .space-top-fix
    padding-top: 0
  .checkout-page
    padding: 0 0

dl.checkout-summary
  margin-top: -16px

dl dt.extra-info-text
  width: 100%

dl dd.extra-info-text
  width: 0%

dl dt.extra-info-text.rates
  // margin-top: -6px
  margin-top: -4px
  // padding-bottom: 4px
  padding-bottom: 4px
  // padding-bottom: 20px
  // opacity: .6
  color: #999

dl dt.extra-info-text.last
  // padding-top: -5px
  padding-top: 12px
  margin-bottom: -20px
  color: #999
  line-height: 1.3


.secure-checkout-message
  line-height: 1.2
.secure-checkout-message small.secure-message
  color: #999


@media (max-width: 480px)
  header.light
    padding-top: 10px
  .extra-space-top
    padding-top: 0px


// Button fix for a final time

a[data-visited].button-full, a[data-visited].button, a[data-visited].button-full, a[data-visited].button, .content a.button:visited, .content a.button[data-visited], .content a.button, a.button
  border: #238cfa solid 1px
  color: #fff

// a[data-visited].button-full:hover, a[data-visited].button:hover, a[data-visited].button-full:hover, a[data-visited].button:hover, .content  .button a:visited:hover, .content  .button a[data-visited]:hover, .content .button a:hover,  a.button:hover, a.button-full:visited:hover, a.button:visited:hover, a.button-full:visited:hover, a.button:visited:hover
//
//   border: #238cfa solid 1px
//   color: #fff

// .content a.button:visited:hover, .content a.button[data-visited]:hover
//   border: #238cfa solid 1px
//   border-bottom: #238cfa solid 1px
//   color: #fff


dl dt.checkout-quantity
  width: 100%



// Mobile quick fixes to the home page.
// @media screen and (max-width: 767px) and (min-width: 661px)
//   .home-page .hero h1
//     margin-top: -120px
// @media screen and (max-width: 660px) and (min-width: 487px)
//   .home-page .hero h1
//     margin-top: -90px
// @media screen and (max-width: 488px) and (min-width: 421px)
//   .home-page .hero h1
//     margin-top: -130px
// @media screen and (max-width: 420px) and (min-width: 381px)
//   .home-page .hero h1
//     margin-top: -70px
// @media screen and (max-width: 380px)
//   .home-page .hero h1
//     margin-top: -110px
//   .side-foto
//     width: 90%
// end of mobile fix

// Fix of the pagination on the articles page
@media screen and (max-width: 420px)
  .pagination
    margin: 14px 0 30px 0
// @media screen and (max-width: 420px)
//   .pagination
//     margin: 5px 0
// end articles page pagination fix


.content a.button-full:visited:hover, .content a.button:visited:hover, .content a[data-visited].button-full:hover, .content a[data-visited].button:hover a[data-visited].button-full, a[data-visited].button, a[data-visited].button-full, a[data-visited].button, a[data-visited].button-full, a[data-visited].button, a[data-visited].button-full, a[data-visited].button, .content a.button-full:visited, .content a.button:visited, .content a[data-visited].button-full, .content a[data-visited].button, .content a.button-full, .content a.button, a.button-full, a.button,
.button
  border: #238cfa solid 1px
  border-bottom: #238cfa solid 1px
  color: #fff
  background-color: #238cfa


.content a.button-full.grey:visited:hover, .content a.button.grey:visited:hover, .content a[data-visited].button-full.grey:hover, .content a[data-visited].button.grey:hover a[data-visited].button-full.grey, a[data-visited].button.grey, a[data-visited].button-full.grey, a[data-visited].button.grey, a[data-visited].button-full.grey, a[data-visited].button.grey, a[data-visited].button-full.grey, a[data-visited].button.grey, .content a.button-full.grey:visited, .content a.button.grey:visited, .content a[data-visited].button-full.grey, .content a[data-visited].button.grey, .content a.button-full.grey, .content a.button.grey, a.button-full.grey, a.button.grey,
.button.grey
  border: #e3e3e3 solid 1px
  border-bottom: #e3e3e3 solid 1px
  color: #272727
  background-color: #e3e3e3

.content a.button-full.light:visited:hover, .content a.button.light:visited:hover, .content a[data-visited].button-full.light:hover, .content a[data-visited].button.light:hover a[data-visited].button-full.light, a[data-visited].button.light, a[data-visited].button-full.light, a[data-visited].button.light, a[data-visited].button-full.light, a[data-visited].button.light, a[data-visited].button-full.light, a[data-visited].button.light, .content a.button-full.light:visited, .content a.button.light:visited, .content a[data-visited].button-full.light, .content a[data-visited].button.light, .content a.button-full.light, .content a.button.light, a.button-full.light, a.button.light,
.button.light
  border: #238cfa solid 1px
  border-bottom: #238cfa solid 1px
  color: #238cfa
  background-color: #fff

  // border: #238cfa solid 1px
  // border-bottom: #238cfa solid 1px
  // color: #fff
  // background-color: #238cfa


@media screen and (max-width: 767px)
  .button
    margin: 10px 0

.productstep-page .top-movie-wrap, .productother-page .top-movie-wrap
  padding-top: 0

.upper
  text-transform: uppercase

.dark-mode a
  color: #111

.dark-mode .darkButton i
  padding-right: 10px


h3
  margin-top: 42px
  margin-bottom: 22px

@media only screen and (max-width: 737px)
  figure .right, .content .right
    margin-left: 0
    margin-right: 20px
  figure .left, .content .left
    margin-left: 20px
    margin-right: 0

@media only screen and (max-width: 360px)
  figure .left, .content .left, figure .right, .content .right
    float: none
    clear: both


form
  padding-top: 18px
