
@media screen and (max-width: 420px) {
  .content p.stepoftotal {
    margin: 0;
    margin-bottom: -10px;
    padding-top: 10px;
  }
}

.productstep-page .content p {
  margin-bottom: 0;
}
.content p.stepoftotal {
  margin: 0;
  margin-bottom: -10px;
  padding-top: 10px;
}

.blockscroll {
  height: 100vh;
}

.custom-social-proof {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999999999999 !important;
  display: none;  /* Uncoment This Line to Hide Initially*/
  // font-family: 'Open Sans', sans-serif;
  .custom-notification {
    position: relative;
    z-index: 99999;
    box-sizing: border-box;
    width: 320px;
    border: 1px solid #dbdbdb;
    // border: 0;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 2px 2px 10px 2px hsla(0, 4%, 4%, .2);
    text-align: left;
    font-weight: 400;
    cursor: pointer;
    .custom-notification-container {
      display: flex !important;
      height: 80px;

      align-items: center;
      .custom-notification-image-wrapper {
        img {
          overflow: hidden;
          // padding-top: 5px;
          max-height: 80px;
          width: 80px;
          // border-radius: 40px 0 0 40px;
          border-radius: 40px;

          object-fit: cover;
        }
      }
      .custom-notification-content-wrapper {
        display: flex !important;
        flex-direction: column;
        margin: 0;
        padding-right: 20px;
        padding-left: 20px;
        height: 100%;
        border-radius: 0 6px 6px 0;
        // color: gray;

        flex: 1;
        justify-content: center;
        .custom-notification-content {
          margin: 0 !important;
          padding: 0 !important;
          color: #111;
          font-size: 14px;
          font-family: inherit !important;
          line-height: 16px;
          .custom-notification-text {
            display: block !important;
            padding-top: 6px;
          }
          small {
            display: block !important;
            // margin-top: 3px !important;
            font-size: 12px !important;
            line-height: 16px;
            opacity: .6;
          }
        }
      }
    }
    // .custom-close {
    //   position: absolute;
    //   top: 30px;
    //   right: 18px;
    //   width: 12px;
    //   height: 12px;
    //   opacity: 0;
    //   cursor: pointer;
    //   transition: .2s ease-in-out;
    //   transform: rotate(45deg);
    //   &::before {
    //     position: absolute;
    //     top: 5px;
    //     left: 0;
    //     display: block;
    //     width: 100%;
    //     height: 2px;
    //     background-color: gray;
    //     content: '';
    //   }
    //   &::after {
    //     position: absolute;
    //     top: 0;
    //     left: 5px;
    //     display: block;
    //     width: 2px;
    //     height: 100%;
    //     background-color: gray;
    //     content: '';
    //   }
    // }
    // &:hover {
    //   .custom-close {
    //     opacity: 1;
    //   }
    // }
  }
}


@media only screen and (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .custom-social-proof {
    .custom-notification {
      width: 280px !important;
    }
  }
}


.dark-mode {
  .custom-social-proof {
    .custom-notification {
      .custom-notification-container {
        .custom-notification-content-wrapper {
          .custom-notification-content {
            color: #aaa;
          }
        }
      }
    }
  }
}


// .home-page {
//   .content a:visited:hover, .content a[data-visited]:hover a[data-visited], .content a:hover, a:hover {
//     border
//   }
// }

a {
  font-weight: 700;
  &:hover,
  &:visited,
  &[data-visited] {
    font-weight: 700;
  }
}


.content {
  .breadcrumb-product {
    display: inline;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: none;
    list-style: none;
    // font-size: 12px;
    font-size: 14px;
    i {
      font-size: 10px;
      // was 12px, -6 px from org 18px on font
    }
    ul {
      margin: 0;
      padding: 0;
      padding-left: 0;
      list-style: none;
      li {
        display: inline;
        padding-left: 10px;
        // padding: 4px 8px;
        list-style: none;
        &:first-of-type {
          padding-left: 0;
        }
        a {
          border-bottom: none;
          color: #111;
          &:hover,
          &:visited,
          &[data-visited] {
            border-bottom: none;
            color: #111;
          }
        }
      }
    }
  }
}
.dark-mode {
  .content {
    .breadcrumb-product {
      i {
        color: #aaa;
      }
      ul {
        li {
          a {
            color: #aaa;
            &:hover,
            &:visited,
            &[data-visited] {
              color: #aaa;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    .breadcrumb-product {
      // i {
      //   font-size: 10px;
      //   // was 12px, -6 px from org 18px on font
      // }
      ul {
        li {
          display: block;
          padding-bottom: 2px;
          &:first-of-type {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .togglefix {
    overflow: hidden;
    max-width: 185px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.micro-link {
  // display: inline;
  margin: 0;
  // border-radius: 0;
  // margin-top: -9px;
  margin-top: -5px;
  // padding: 0;
  padding-bottom: 20px;
  a {
    display: inline;
    margin: 0;
    padding: 0;
    border-bottom: none;
    border-radius: 0;
    color: #111;
    font-size: 14px;
    &:hover,
    &:visited,
    &[data-visited] {
      border-bottom: none;
      color: #111;
    }
  }
}
.dark-mode {
  .micro-link {
    a {
      color: #aaa;
      &:hover,
      &:visited,
      &[data-visited] {
        color: #aaa;
      }
    }
  }
}
a.button,
button {
  font-weight: 800;
  &:hover,
  &:visited,
  &[data-visited] {
    font-weight: 800;
  }
}
// html {
//   background-color: #fff;
// }
// html .dark-mode {
//   background-color: #171717;
// }


#fade-wrapper {
  /* display: none; */
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .85);
  /* height: 100%; */
  /* width: 100%; */
}
#fade-wrapper .overlay-content {
  position: relative;
  position: absolute;
  top: 50%;
  top: 50%;
  left: 50%;
  margin-right: auto;
  margin-right: -50%;
  margin-left: auto;
  width: 280px;
  text-align: center;
  transform: translate(0, -50%);
  transform: translate(-50%, -50%);
}
#fade-wrapper .overlay-content h2 {
  color: #fff;
}

#fade-wrapper-session {
  /* display: none; */
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .85);
  /* height: 100%; */
  /* width: 100%; */
}
#fade-wrapper-session .overlay-content {
  position: relative;
  position: absolute;
  top: 50%;
  top: 50%;
  left: 50%;
  margin-right: auto;
  margin-right: -50%;
  margin-left: auto;
  width: 280px;
  text-align: center;
  transform: translate(0, -50%);
  transform: translate(-50%, -50%);
}
#fade-wrapper-session .overlay-content h2 {
  color: #fff;
}


.videoloadertext {
  z-index: -1;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 21%;
  padding-bottom: 20%;
  // width: 256px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 48px;
  opacity: .3;
  -webkit-animation: fade 2s linear infinite;
  // opacity: 1;
          animation: fade 2s linear infinite;
  // animation-iteration-count: infinite;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: .3;
  }
}

@media screen and (max-width: 660px) {
  .videoloadertext {
    font-size: 38px;
  }
}
@media screen and (max-width: 420px) {
  .videoloadertext {
    font-size: 28px;
  }
}

.durationstamp {
  padding-top: 2px;
  color: #111;
  font-weight: 400;
  opacity: .7;
}
.dark-mode {
  .durationstamp {
    color: #aaa;
    opacity: .7;
  }
}

.product-page {
  ol,
  ul {
    li {
      margin: 1px 0;
    }
  }
}


// Very very green #23fa92
ol li.productstep-completed:before {
  // color: #00b349;
  color: #111;
}
li.productstep-completed {
  // border-color: #00b349;
  border-color: #238cfa;
  // color: #00b349;
  color: #238cfa;
  .checkcompletemark {
    margin-bottom: -1px;
  }
  .checkcomplete {
    fill: #00b349;
  }
  a {
    // border-color: #00b349;
    border-color: #238cfa;
    // color: #00b349;
    color: #238cfa;
    &:hover,
    &:visited,
    &[data-visited] {
      // border-color: #00b349;
      border-color: #238cfa;
      // color: #00b349;
      color: #238cfa;
    }
  }
}
// li.productstep-completed {
//   .checkcompletemark {
//     &:last-of-type {
//       display: none;
//     }
//   }
// }

// span.checkcompletemark:last-of-type {
//   display: none;
// }

.dark-mode {
  ol li.productstep-completed:before {
    // color: #00b349;
    color: #aaa;
  }
  li.productstep-completed {
    // border-color: #00b349;
    border-color: #238cfa;
    // color: #00b349;
    color: #aaa;
    .checkcompletemark {
      margin-bottom: -1px;
    }
    .checkcomplete {
      fill: #00b349;
    }
    a {
      // border-color: #00b349;
      border-color: #238cfa;
      // color: #00b349;
      color: #aaa;
      &:hover,
      &:visited,
      &[data-visited] {
        // border-color: #00b349;
        border-color: #238cfa;
        // color: #00b349;
        color: #aaa;
      }
    }
  }
}

@media screen and (max-width:520px) {
  .auth-page,
  .login-page {
    form {
      padding-top: 0;
    }
    .block {
      margin: 0;
      padding: 16px 20px;
      h2 {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}


.content a.button.light.red {
  border-width: 2px;
  // rgb(236, 29, 29) is the very very red color
  border-color: rgb(236, 29, 29) !important;
  color: rgb(236, 29, 29) !important;
  &:hover,
  &:visited,
  &[data-visited] {
    border-width: 2px;
    border-color: rgb(236, 29, 29) !important;
    color: rgb(236, 29, 29) !important;
  }
}


// .move-right {
//   text-align: right;
// }
.move-right {
  float: right;
}

del {
  text-decoration: line-through;

  text-decoration-thickness: 2px;
}
h1 del {
  text-decoration: line-through;

  text-decoration-thickness: 7px;
}
h2 del {
  text-decoration: line-through;

  text-decoration-thickness: 5px;
}
h3 del {
  text-decoration: line-through;

  text-decoration-thickness: 4px;
}
h4 del {
  text-decoration: line-through;

  text-decoration-thickness: 3px;
}
h5 del {
  text-decoration: line-through;

  text-decoration-thickness: 2px;
}
a.button del {
  text-decoration: line-through;

  text-decoration-thickness: 3px;
}
