.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;

  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
     -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
       -o-transition: height 500ms ease-in-out;
          transition: height 500ms ease-in-out;
}

.owl-carousel {
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-stage {
  position: relative;

  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
  line-height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  float: left;
  min-height: 1px;

  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;

  -webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;

  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  display: block;
  opacity: 0;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor:      -o-grab;
  cursor:     -ms-grab;
  cursor:         grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
     -moz-transition: opacity 400ms ease;
      -ms-transition: opacity 400ms ease;
       -o-transition: opacity 400ms ease;
          transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  background: url('//www.baptistella.xyz/assets/owl/owl.video.play.png') no-repeat;
  cursor: pointer;
  -webkit-transition: scale 100ms ease;
     -moz-transition: scale 100ms ease;
      -ms-transition: scale 100ms ease;
       -o-transition: scale 100ms ease;
          transition: scale 100ms ease;

  -webkit-backface-visibility: hidden;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
     -moz-transition: scale(1.3, 1.3);
      -ms-transition: scale(1.3, 1.3);
       -o-transition: scale(1.3, 1.3);
          transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  height: 100%;
  background-position: center center;
  -webkit-background-size: contain;
     -moz-background-size: contain;
       -o-background-size: contain;
          background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
     -moz-transition: opacity 400ms ease;
      -ms-transition: opacity 400ms ease;
       -o-transition: opacity 400ms ease;
          transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
