//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  margin-bottom: $jumbotron-padding;
  padding-top: $jumbotron-padding;
  padding-bottom: $jumbotron-padding;
  background-color: $jumbotron-bg;
  color: $jumbotron-color;

  h1,
  .h1 {
    margin-bottom: $padding-large-vertical;
    color: $jumbotron-heading-color;
    line-height: $headings-line-height * .6;
  }

  p {
    margin-bottom: ($jumbotron-padding / 2);
    font-weight: 200;
    font-size: $jumbotron-font-size;
  }

  > hr {
    border-top-color: darken($jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: $border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: $screen-sm-min) {
    padding-top: ($jumbotron-padding * 1.6);
    padding-bottom: ($jumbotron-padding * 1.6);

    .container &,
    .container-fluid & {
      padding-right: ($jumbotron-padding * 2);
      padding-left: ($jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: $jumbotron-heading-font-size;
    }
  }
}
