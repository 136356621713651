.loader {
  @include user-select(none);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $loader-bg;
  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -$loader-size / 2;
    margin-left: -$loader-size / 2;
  }
}

.spinner {
  width: $loader-size;
  height: $loader-size;
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  .path {
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;

    stroke-dasharray: 1, 150;
    /* 1%, 101% circumference */
    stroke-dashoffset: 0;
    stroke: $loader-color;
    stroke-linecap: round;
  }
  @keyframes rotate {
    100% {
      @include rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      /* 1%, 101% circumference */
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      /* 70%, 101% circumference */
      stroke-dashoffset: -35;
    }
    /* 25% circumference */
    100% {
      stroke-dasharray: 90, 150;
      /* 70%, 101% circumference */
      stroke-dashoffset: -124;
    }
  }

  /* -99% circumference */
  @-webkit-keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      /* 1%, 101% circumference */
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      /* 70%, 101% circumference */
      stroke-dashoffset: -35;
    }
    /* 25% circumference */
    100% {
      stroke-dasharray: 90, 150;
      /* 70%, 101% circumference */
      stroke-dashoffset: -124;
    }
  }

  /* -99% circumference */
  .app-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 600px) {
    body {
      overflow-x: auto;
    }
    .app-wrapper {
      width: 600px;
    }
  }
}
