ul {
  list-style-type: square;
}
.content ul {
  padding-left: 30px;
  li {
    padding-left: 10px;
  }
}


a[data-visited].button-full,
a[data-visited].button,
.content a:visited,
.content a[data-visited],
.content a,
a {
  border-color: #238cfa;
  color: #238cfa;
  text-decoration: none;
}
// a.button-full:visited:hover,
// a.button:visited:hover,
a[data-visited].button-full:hover,
a[data-visited].button:hover,
.content a:visited:hover,
.content a[data-visited]:hover,
.content a:hover,
a:hover {
  border-color: #111;
  color: #111;
  text-decoration: none;
}
a.button-full:visited,
a.button:visited,
a.button {
  border-color: #238cfa;
  color: #fff;
  text-decoration: none;
}
a.button-full:visited:hover,
a.button:visited:hover, {
  border-color: #238cfa ;
  color: #111;
  text-decoration: none;
}


figure .left,
.article-content .left,
.content .left {
  float: left;
  margin: 10px;
}

figure .right,
.article-content .right,
.content .right {
  float: right;
  margin: 10px;
}

@media only screen and (max-width:737px) {
  figure .left,
  .article-content .left,
  .content .left {
    float: left;
  }

  figure .right,
  .article-content .right,
  .content .right {
    float: left;
  }
}

.no-header {
  padding-top: 60px;
}
@media only screen and (max-width:660px) {
  .no-header {
    padding-top: 20px;
  }
}
// Unsure about the style above.
figure img,
img {
  max-width: 100%;
}
figure.center {
  text-align: center;
}
figure.center figcaption {
  text-align: left;
}
// figure.left {
//   text-align: left;
// }
// figure.right {
//   text-align: right;
// }

// .content a {
//   word-break: break-all;
// }

ul.toc {
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
  list-style: none;
  list-style-type: none;
  li {
    margin: 0;
    padding: 1px;
    a {
      &::before {
        content: '#  ';
      }
    }
  }
}

#topbutton {
  position: fixed; /* Fixed/sticky position */
  right: 30px; /* Place the button 30px from the right */
  bottom: 20px; /* Place the button at the bottom of the page */
  z-index: 99; /* Make sure it does not overlap */
  display: none; /* Hidden by default */
  padding: 15px; /* Some padding */
  outline: none; /* Remove outline */
  border: none; /* Remove borders */
  border-radius: 10px; /* Rounded corners */
  // background-color: white; /* Set a background color */
  // color: blue; /* Text color */
  font-size: 18px; /* Increase font size */
  cursor: pointer; /* Add a mouse pointer on hover */
}

#topbutton:hover {
  // background-color: #555; /* Add a dark-grey background on hover */
}

.wrapper {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

// .side-foto img {
//   position: fixed;
//   z-index: 0;
// }


.button {
  @extend .button;
  @extend .button-full;
  @extend .btn-block;
}
hr {
  margin-top: 29px;
  margin-bottom: 29px;
  border: 0;
  border-top: 1px solid #eaeaea;
}

h1 {
  // line-height: .96;
  line-height: .95;
}
h2 {
  line-height: .95;
}
h3,
h4 {
  line-height: .95;
}
h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: .95;
}

h6 {
  // color: #585858;
  color: #888;
  // font-size: 12px;
  font-size: 9px;
}
figcaption {
  padding-top: 10px;
  color: #404040;
  font-style: italic;
  font-size: 13.5px;
  line-height: 1.2;
}
.caption {
  color: #404040;
  font-style: italic;
  font-size: 13.5px;
  line-height: 1.2;
}
span.caption {
  color: #404040;
  font-style: italic;
  font-size: 13.5px;
  line-height: 1.2;
}
.small {
  // color: #111;
  color: #585858;
  font-size: 9px;
  line-height: 1.1;
  &.gray {
    color: #999;
  }
  &.dark {
    color: #111;
  }
  &.normal {
    // color: #111;
    color: #585858;
  }
  &.yes {
    // color: #111;
    color: #585858;
  }
  &.allgray {
    color: #999;
  }
}
.content {
  span.allgray a {
    border-bottom: none;
    color: #999;
    line-height: 1.1;
    &:hover {
      border-bottom: none;
      color: #999;
    }
  }
}
.content {
  span.allgray a[data-visited],
  span.allgray a:visited {
    border-bottom: none;
    color: #999;
    line-height: 1.1;
    &:hover {
      border-bottom: none;
      color: #999;
    }
  }
}
blockquote {
  display: block;
  color: #808080;
  font-style: italic;
  line-height: 1.45;
}
.content blockquote span.quote-link,
.content blockquote a.quote-link {
  display: block;
  float: right; // Under doubt
  margin-right: 10px;
  padding-top: 6px;
  border-bottom: none;
  color: #808080;
  text-align: right;
  font-style: italic;
  font-size: 18px;
}
.content blockquote a:visited,
.content blockquote a[data-visited] {
  border-bottom: none;
  color: #808080;
  &:hover {
    border-bottom: none;
    color: #808080;
  }
}

p .textright {
  float: right;
  clear: both;
  text-align: right;
}
p .textleft {
  text-align: left;
}
p .textcenter {
  text-align: center;
}

// .content a:visited,
// .content a[data-visited] {
//   border-bottom: 2px solid #585858;
//   color: #585858;
// }

#bookingjs,
.bookingjs {
  a {
    border-bottom: none;
  }
}


.tagmenu {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  // border: 1px solid #ccc;
  // border-width: 1px 0;
  list-style: none;
  text-align: center;
  font-size: 15px;
}
.tagmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tagmenu li {
  display: inline;
  list-style: none;
}
.tagmenu a {
  display: inline-block;
  padding: 8px;
  border-bottom: none;
}
.tagmenu a:hover {
  border-bottom: none;
  opacity: .8;
}
.tagmenu a.active {
  opacity: .3;
}
.tagandtime {
  padding-top: 3px;
  font-size: 15px;
}
.tagandtime a,
.tagandtime a:hover {
  border-bottom: none;
}
.backhome {
  font-size: 15px;
}
.backhome a,
.backhome a:hover {
  border-bottom: none;
}

.hiddenmobil {
  display: none;
}
.smaller-icon {
  font-size: 12px;
}
.tagmenutoggle,
.tagmenutoggle i {
  display: block;
  text-align: center;
}
.rotate {
  -webkit-transition: all .2s linear;
     -moz-transition: all .2s linear;
          transition: all .2s linear;
}
.rotate.up {
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media only screen and (max-width: 960px) {
  .tagmenu {
    // text-align: left;
    font-size: 15px;
  }
  .tagmenu li {
    display: block;
  }
  .tagmenu ul {
    // margin-left: 20px;
  }
  .tagmenu a {
    display: block;
    padding: 10px;
  }
}

// Bug fixes
@media only screen and (max-width: 420px) {
  .header.cf {
    // height: 100px;
    min-height: 100px;
  }
  .header a.logo {
    margin-top: 12px;
  }
}
@media only screen and (max-width: 960px) {
  .header.cf {
    // height: 100px;
    min-height: 100px;
  }
  // .header a.logo {
  //   margin-top: 12px;
  // }
}
// New code
//
.backbuttonfoo {
  padding-top: 30px;
}
.morecontent {
  padding-bottom: 5px;
}
.freeconnection {
  padding-top: 10px;
  background: rgba(0,0,0,.05);
  &::after {
    background: none;
  }
}

.extra-space-top {
  padding-top: 40px;
}

// figure.top-movie-wrap {
//   figcaption {
//     margin-top: 10px;
//   }
// }

figure.top-movie-wrap + figcaption {
  margin-top: -10px;
}



@media only screen and (max-width: 767px) {
  .side-foto {
    position: unset;
    float: right;
    margin: -10px 0 -20px 0;
    padding-bottom: 10px;
    width: 50%;
  }
  .side-foto img {
    position: unset;
  }
}
@media only screen and (max-width: 567px) {
  .side-foto {
    padding-bottom: 20px;
  }
}



// ol {
//   padding-left: 40px;
//   list-style: none;
//   counter-reset: my-awesome-counter;
// }
// ol li {
//   counter-increment: my-awesome-counter;
// }
// ol li::before {
//   color: #111;
//   content: counter(my-awesome-counter) '. ';
//   font-weight: bold;
// }

// ol {
//   padding-left: 40px;
//   list-style: none;
//   counter-reset: my-awesome-counter;
// }
/* Tell the <li> to not display numbers, but keep track of what the numbers should be */
ol li {
  position: relative;
  padding-left: 0;
  list-style-type: none;
  counter-increment: list;
}
/* Outout the numbers using the counter() function, but use a custom color, and position the numbers how we want */
ol li:before {
  position: absolute;
  left: -30px;
  width: 24px;
  color: #111;
  content: counter(list) '.';
  text-align: right;
  font-weight: 700;
}
