a {
  border-bottom: 2px solid #077fb6;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

a.logo,
.menu li:hover a,
.menu li a {
  border: none;
}
.menu li:hover a {
  color: #111;
}
