/* Dropdown Button */
.dropbtn {
  // padding: 16px;
  padding: 12px 12px 10px 12px;
  border: none;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  // background-color: #2980b9;
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  // display: inline-block;
  display: block;
}
.dropdown .dropdown-content a {
  margin-top: 3px;
  // display: block;
  margin-bottom: 1px;
  color: #111;
  vertical-align: middle;
  font-weight: 500;
}
.dropdown .dropdown-content a span svg {
  // margin-top: 3px;
  // margin-bottom: -1px;
  margin-top: -3px;
  vertical-align: middle;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  z-index: 1;
  display: none;
  padding-top: 17px;
  padding-bottom: 17px;
  // min-width: 160px;
  min-width: 100%;
  border-radius: 20px;
  // background-color: #f1f1f1;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
}
@media screen and (max-width: 767px) {
  .dropdown-content {
    margin-top: -10px;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  // left: 35%;
  display: block;
  // margin-left: -5%;
  padding: 14px 16px;
  border-bottom: none;
  color: black;
  text-align: center;
  text-decoration: none;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.cal-online-text {
  color: #d0d0d0;
  font-size: 12px;
}


.content .dropbtn a:visited,
.content .dropbtn a[data-visited],
.content .dropbtn a,
.dropbtn a,
.content .dropbtn a:visited:hover,
.content .dropbtn a[data-visited]:hover,
.content .dropbtn a:hover,
.dropbtn a:hover,
.content .dropbtn a:visited:hover,
.content .dropbtn a[data-visited]:hover,
.content .dropbtn a:hover,
.dropbtn a:hover {
  border-bottom: none;
}

.dropdown-spacer {
  // .end-spacer
  // Before adding Proton
  // margin-bottom: 380px;
  margin-bottom: 435px;
}
