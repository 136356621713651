*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.content .ac-custom ul {
  padding-left: 0;
}
.content .ac-custom label {
  margin-bottom: 22px;
}
.content .ac-custom h4 {
  margin-top: 30px;
  padding-top: 0;
  padding-bottom: 10px;
}

.ac-custom {
  margin: 0 auto;
  // padding: 0 3em;
  // max-width: 900px;
}

.ac-custom h4 {
  padding-top: 20px;
}
.ac-custom h4 {
  // margin: 0 0 30px;
  // padding: 0 0 .5em;
  // font-weight: 300;
  // font-size: 3em;
}

.ac-custom ul,
.ac-custom ol {
  margin: 0 auto;
  padding: 0;
  // max-width: 800px;
  list-style: none;
}

.ac-custom li {
  position: relative;
  margin: 0 auto;
  // padding: 1em 0;
  font-weight: 400;
}

.ac-custom label {
  position: relative;
  display: inline-block;
  padding: 3px 0 0 50px;
  // color: rgba(0,0,0,.2);
  color: #111;
  vertical-align: top;
  font-weight: 400;
  font-style: normal;
  // font-size: 2em;
  cursor: pointer;
  -webkit-transition: color .3s;
          transition: color .3s;
}

.ac-custom input[type='checkbox'],
.ac-custom input[type='radio'],
.ac-custom label::before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -25px;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.ac-custom input[type='checkbox'],
.ac-custom input[type='radio'] {
  z-index: 100;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;

  -webkit-appearance: none;
}

.ac-custom label::before {
  border: 4px solid #272727;
  content: '';
  -webkit-transition: opacity .3s;
          transition: opacity .3s;
}

.ac-radio label::before {
  border-radius: 50%;
}

.ac-custom input[type='checkbox']:checked + label,
.ac-custom input[type='radio']:checked + label {
  color: #111;
  font-weight: 800;
}

.ac-custom input[type='checkbox']:checked + label::before,
.ac-custom input[type='radio']:checked + label::before {
  // opacity: .8;
  color: #111;
}

/* General SVG and path styles */

.ac-custom svg {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  pointer-events: none;
}

.ac-custom svg path {
  // stroke: #fdfcd3;
  stroke: #e6e600;
  // stroke: #ff0;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

/* Specific input, SVG and path styles */

/* Circle */
.ac-circle input[type='checkbox'],
.ac-circle input[type='radio'],
.ac-circle label::before {
  position: absolute;
  left: 6px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
}

.ac-circle label::before {
  border: none;
  // background-color: #444;
  background-color: #aaa;
  // background-color: #454545;
  // background-color: rgba(69,69,69,.4);
  // background-color: #272727;
}
.ac-circle input[type='radio']:checked + label::before {
  border: none;
  // background-color: #444;
  background-color: #111;
  // background-color: rgba(69,69,69,1);
  // background-color: #272727;
}

.ac-circle svg {
  left: 7px;
  margin-top: -28px;
  width: 40px;
  height: 40px;
}

.ac-circle svg path {
  stroke-width: 10px;
}

/* Box Fill */
.ac-boxfill svg path {
  stroke-width: 8px;
}

/* Swirl */
.ac-swirl svg path {
  stroke-width: 8px;
}

/* List */
.ac-list ol {
  list-style: decimal;
  list-style-position: inside;
}

.ac-list ol li {
  padding: 1em 1em 0 2em;
  text-indent: -40px;
  // font-size: 2em;
}

.ac-list ol li label {
  padding-left: 30px;
  text-indent: 0;
  // font-size: 1em;
}

.ac-list label::before {
  display: none;
}

.ac-list svg {
  top: 1.2em;
  left: 0;
  margin-top: 0;
  width: 100%;
  height: 80px;
}

.ac-list svg path {
  stroke-width: 4px;
}

/* Media Queries */
@media screen and (max-width: 50em) {
  section {
    font-size: 80%;
  }
}
