.clear {
  clear: both;
}
.section-space {
  padding: $padding-large-vertical * 5 0;
}

.back-gray {
  clear: both;
  margin-bottom: 20px;
  padding-top: 5px;
  height: 70px;
  // background: rgba(0,0,0,.03);
  background: rgba(150,207,234,.2);
}

.bold-text {
  color: #272727;
  font-weight: 800;
  font-size: 14px;
}
.form-group.floating-label .control-label {
  left: -36px !important;
}

.ultra-violet {
  background: #5f4b8b;
}

.side-foto {
  position: relative;
  // float: right;
  // text-align: right;
  right: 0;

  img {
    position: absolute;
    right: 0;
    // margin-right: -10px;
    // margin-left: auto;
    width: 550px;
  }
}

// Mini hack for media hero
.media-width {
  padding: 20px 0;
}


.extra-bottom-space {
  padding-bottom: 40px;
}
