.player {
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;

  align-items: center;
  // align-items: flex-start;
  // align-items: baseline;
  flex-wrap: wrap;
}

.player__controls {
  z-index: 1;
  // flex-basis: 12.5%;
  width: 80px;
}


.player__progress {
  margin-top: -10px;
  padding-top: 4px;
  min-width: 0;
  line-height: .9;

  flex-grow: 1;
  // flex-basis: 87.5%;
}

.player__time {
  z-index: 0;
  margin-top: -18px;
  margin-right: 2px;
  text-align: right;
  font-size: 14px;

  flex-basis: 100%;
}

progress {
  width: 100%;
  height: 7px;
  border-radius: 7px;
}
progress::-webkit-progress-bar {
  border-radius: 7px;
  background-color: rgba(17,17,17,.05);
}
progress::-webkit-progress-value {
  border-radius: 7px;
  // background-color: #23fa92;
  background-color: #238cfa;
}
progress::-moz-progress-bar {
  border-radius: 7px;
  // background-color: #23fa92;
  background-color: #238cfa;
}

.dark-mode {
  progress {
    width: 100%;
    height: 7px;
    border-radius: 7px;
  }
  progress::-webkit-progress-bar {
    border-radius: 7px;
    background-color: rgba(255,255,255,.15);
  }
  progress::-webkit-progress-value {
    border-radius: 7px;
    // background-color: #23fa92;
    background-color: #238cfa;
  }
  progress::-moz-progress-bar {
    border-radius: 7px;
    // background-color: #23fa92;
    background-color: #238cfa;
  }
}


audio::-webkit-media-controls {
  display: none !important;
}

audio {
  display: none;
  visibility: hidden;
}



.button.player__button {
  width: 60px;
  cursor: pointer;
}

.player__button i {
  margin-top: 5px;
  font-size: 22px;
}

.player__button i.icon-control-play {
  padding-left: 4px;
}

.player__button i.icon-control-pause {
  padding-right: 0;
}
