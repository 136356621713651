/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

// @page :first {
//   margin-top: 250pt;
// }
// @page :left {
//   margin-right: 200pt;
// }
//
// @page :right {
//   margin-left: 200pt;
// }

@media print {
  .noprint {
    display: none;
  }
  a {
    color: #000 !important;
  }
}

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    text-shadow: none !important;
  }
  body {
    // margin-top: 50mm;
    // margin-right: 0mm;
    // margin-bottom: 50mm;
    // margin-left: 0mm;
    margin: 30px 20px;
    white-space: normal;
  }
  // @page {
  //   margin-top: 5cm;
  //   margin-bottom: 5cm;
  // }
  @page {
    margin-top: 10cm;
    margin-right: 1cm;
    margin-bottom: 2cm;
    margin-left: 1cm;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  // a[href]:after {
  //   content: ' (' attr(href) ')';
  // }
  //
  // abbr[title]:after {
  //   content: ' (' attr(title) ')';
  // }
  //
  // // Don't show links that are fragment identifiers,
  // // or use the `javascript:` pseudo protocol
  // a[href^='#']:after,
  // a[href^='javascript:']:after {
  //   content: '';
  // }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-before: always;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    break-before: page;
    page-break-before: always;
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    break-before: page;
    page-break-before: always;
    page-break-after: avoid;
  }

  // Bootstrap specific changes start

  // Bootstrap components
  .navbar {
    display: none;
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  // Print touchups for Baptistella.xyz
  // @media print {
  //
  // }

  .side-foto img {
    display: none !important;
  }
  footer.footer {
    display: none !important;
  }
  a.button {
    border: 0 solid !important;
  }
  .end-spacer {
    display: none !important;
  }
  .extra-bottom-space,
  .ctaform {
    display: none !important;
  }
  #topbutton {
    display: none !important;
  }
  a.button {
    display: none !important;
  }
  .pre-loader {
    display: none !important;
  }
  .col-sm-push-6 {
    left: 0 !important;
  }
  // .col-sm-pull-6 {
  //   left: 0;
  //   display: none !important;
  // }
  .col-sm-offset-2 {
    display: none !important;
  }
  .col-sm-6,
  .col-sm-8 {
    width: 100% !important;
  }
  .checkout-page form {
    display: none !important;
  }




  .langauge-en .wrapper::after {
    padding-left: 16px;
    content: 'Please see www.baptistella.xyz for the latest version of this information.';
    font-size: 12pt;
  }
  .langauge-br .wrapper::after {
    padding-left: 16px;
    content: 'Por favor, consulte www.baptistella.xyz para obter a versão mais recente dessas informações.';
    font-size: 12pt;
  }
  // Bootstrap specific changes end
}
