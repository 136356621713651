/* This stylesheet generated by Transfonter (https://transfonter.org) on March 2, 2018 12:07 AM */

@font-face {
  font-weight: 900;
  font-style: italic;
  font-family: 'Verb Ultra';
  src: url('/assets/fonts/VerbUltra-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbUltra-Italic.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbLight-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbLight-Italic.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbLight.woff2') format('woff2'),
  url('/assets/fonts/VerbLight.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbMedium.woff2') format('woff2'),
  url('/assets/fonts/VerbMedium.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbSemibold.woff2') format('woff2'),
  url('/assets/fonts/VerbSemibold.woff') format('woff');
}

@font-face {
  font-weight: 200;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbExtralight.woff2') format('woff2'),
  url('/assets/fonts/VerbExtralight.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbRegular-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbRegular-Italic.woff') format('woff');
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbExtrabold.woff2') format('woff2'),
  url('/assets/fonts/VerbExtrabold.woff') format('woff');
}

@font-face {
  font-weight: 200;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbExtralight-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbExtralight-Italic.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbSemibold-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbSemibold-Italic.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbBlack.woff2') format('woff2'),
  url('/assets/fonts/VerbBlack.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: 'Verb Ultra';
  src: url('/assets/fonts/VerbUltra.woff2') format('woff2'),
  url('/assets/fonts/VerbUltra.woff') format('woff');
}

@font-face {
  font-weight: 800;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbExtrabold-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbExtrabold-Italic.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbRegular.woff2') format('woff2'),
  url('/assets/fonts/VerbRegular.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbBlack-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbBlack-Italic.woff') format('woff');
}

@font-face {
  font-weight: bold;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbRegular-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/VerbRegular-BoldItalic.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbMedium-Italic.woff2') format('woff2'),
  url('/assets/fonts/VerbMedium-Italic.woff') format('woff');
}

@font-face {
  font-weight: bold;
  font-style: normal;
  font-family: 'Verb';
  src: url('/assets/fonts/VerbRegular-Bold.woff2') format('woff2'),
  url('/assets/fonts/VerbRegular-Bold.woff') format('woff');
}
