//Menu
// $menu-w-xsm: 242px;
$menu-w-xsm: 258px;
$menu-w-sm: 340px;

// $menu-color: #5F4B8B;
$menu-color: #272727;
$menu-opacity: 1;

body.menu-open .wrapper {
    -webkit-transform: translate(-340px, 0);
    -ms-transform: translate(-340px, 0);
    -o-transform: translate(-340px, 0);
    transform: translate(-340px, 0)
}

@media screen and (max-width: 768px) {
    body.menu-open .wrapper {
        -webkit-transform: translate(-242px, 0);
        -ms-transform: translate(-242px, 0);
        -o-transform: translate(-242px, 0);
        transform: translate(-242px, 0)
    }
}

@media screen and (min-width: 1600px) {
    body.menu-open .wrapper {
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        -o-transform: translate(-100px, 0);
        transform: translate(-100px, 0)
    }
}

body.menu-open .wrapper {
    opacity: 0.4;
    filter: alpha(opacity=40)
}


.wrapper {
    position: relative;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s
}


.header .container {
  height: 100%;
  position: relative;
  // margin-top: -10px; // local correction
  // margin-top: -10px; // local correction with multi lang over meny
  z-index: 0;
}

$logo-width: 100px;

a.logo svg#logo-svg.logo-svg {
  width: $logo-width;
  height: $logo-width;
}
.header a.logo {
  // padding-left: 50%;
  width: $logo-width;
  // margin-left: -($logo-width / 2);
  // margin-left: -25px;
  opacity: $menu-opacity;
  // display: block;
  display: inline-block;
  margin-top: 20px;

  img {
    width: $logo-width;
    padding: 20px;
    padding-top: 30px;
    margin: 0 auto;

    .logo {
      width: $logo-width;
      margin: 0 auto;
      display: block;
    }
  }
}

.menu-toggle {
  display: block;
  position: absolute;
  top: 50%;
  @include translate(0, -50%);
  right: $padding-large-horizontal;
  cursor: pointer;
  padding: 9px 0;
  @include transition(all 0.2s);
  // margin-right: 16px; // Mini correction
  margin-right: 0px;

  &:active {
    @include opacity(0.8);
  }

  .menu-toggle-cont {
    // width: 38px;
    width: 39px;
    height: 26px;
    position: relative;
  }

  .line {
    content: "";
    width: 38px;
    height: 2px;
    right: 0;
    // background-color: $light-color;
    background: $menu-color;
    opacity: $menu-opacity;
    display: block;
    position: absolute;
    @include transition(all 0.3s);
  }

  .line-top {
    top: 0;
    @include transform-origin(100% 0);
  }

  .line-middle {
    top: 50%;
    margin-top: -1px;
  }

  .line-bottom {
    bottom: 0;
    @include transform-origin(100% 100%);
  }
  .line-label {
    // color: $white;
    color: $menu-color;
    opacity: $menu-opacity;
    font-size: 14px;
    height: 10px;
    padding-top: 30px;
    display: block;
    position: absolute;
  }
}

.menu-open {

  .menu {
    right: 0;
    overflow-x: scroll;
    margin-bottom: -18px;

    li {
      @include translate(0, 0);
    }
  }

  .line-top, .line-bottom {
    width: 25px;
  }

  .line-top {
    top: 12px;
    @include rotate(45deg);
  }

  .line-middle {
    width: 38px;
  }

  .line-bottom {
    bottom: 12px;
    @include rotate(-45deg);
  }

}

.menu {
  background-color: $white;
  position: fixed;
  z-index: 10000;
  right: -$menu-w-xsm;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: ($padding-large-vertical * 3) 0;
  padding-left: 0;
  width: $menu-w-xsm;
  list-style: none;
  @include transition(right 0.3s);
  @include box-shadow(0 4px 3px rgba(0, 0, 0, 0.12));

  @include breakpoint(sm) {
    right: -$menu-w-sm;
    width: $menu-w-sm;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;

  }

  li {
    text-align: left;
    position: relative;
    padding: 0 ($padding-large-horizontal * 2);
    @include translate($padding-large-horizontal * 5, 0);
    @include transition(transform 0.2s);

    &:hover {
      a {
        color: $text-color;
      }

      background-color: $gray-lighter;
    }

    @include breakpoint(md) {
      @for $i from 0 through 10 {
        &:nth-child(#{$i + 1}) {
          @include transition-delay($i * 0.09s);
        }
      }
    }

    a {
      display: block;
      width: 100%;
      padding: ($padding-large-vertical + 4) 0 $padding-large-vertical;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: normal;
      text-decoration: none;
      color: $gray-light;
    }

    ul {
      margin-top: $padding-large-vertical;
      margin-left: $padding-large-horizontal;
    }
  }
}
