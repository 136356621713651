.wrapper {
  @include transition(opacity .2s ease);

  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

dl {
  display: flex;

  flex-wrap: wrap;

  dt {
    display: block;
    width: 70%;
    font-weight: normal;
  }

  dd {
    display: block;
    width: 30%;
    text-align: right;
    font-weight: normal;
  }
}
