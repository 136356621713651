.navbar {
  margin: 0;
  border: none;

  .navbar-brand {
    text-transform: uppercase !important;
  }

  .navbar-nav {
    a {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
