.legal-menu {
  margin: 0;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 70px;
  list-style: none;
  // border: 1px solid #ccc;
  // text-align: center;
  // border-width: 1px 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline;
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 1;
      &:not(:first-child) {
        padding-left: 10px;
      }
      a {
        display: inline-block;
        // padding: 10px;
      }
      &:hover {
        border-color: #111;
        color: #111;
      }
    }
  }
}
